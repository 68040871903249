import { useFormAction, useNavigation } from '@remix-run/react';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import cryptoJS from 'crypto-js';
import { prisma } from '~/utils/db.server.ts';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error;
	if (
		error &&
		typeof error === 'object' &&
		'message' in error &&
		typeof error.message === 'string'
	) {
		return error.message;
	}
	console.error('Unable to get error message for error', error);
	return 'Unknown Error';
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ??
		request.headers.get('host') ??
		new URL(request.url).host;
	const protocol = host.includes('localhost') ? 'http' : 'https';
	return `${protocol}://${host}`;
}

/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
	condition: any,
	message: string | (() => string),
): asserts condition {
	if (!condition) {
		throw new Error(typeof message === 'function' ? message() : message);
	}
}

/**
 * Provide a condition and if that condition is falsey, this throws a 400
 * Response with the given message.
 *
 * inspired by invariant from 'tiny-invariant'
 *
 * @example
 * invariantResponse(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsey
 */
export function invariantResponse(
	condition: any,
	message: string | (() => string),
	responseInit?: ResponseInit,
): asserts condition {
	if (!condition) {
		throw new Response(typeof message === 'function' ? message() : message, {
			status: 400,
			...responseInit,
		});
	}
}

/**
 * Combine multiple header objects into one (uses append so headers are not overridden)
 */
export function combineHeaders(
	...headers: Array<ResponseInit['headers'] | null | undefined>
) {
	const combined = new Headers();
	for (const header of headers) {
		if (!header) continue;
		for (const [key, value] of new Headers(header).entries()) {
			combined.append(key, value);
		}
	}
	return combined;
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
	formAction,
	formMethod = 'POST',
	state = 'non-idle',
}: {
	formAction?: string;
	formMethod?: 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';
	state?: 'submitting' | 'loading' | 'non-idle';
} = {}) {
	const contextualFormAction = useFormAction();
	const navigation = useNavigation();
	const isPendingState =
		state === 'non-idle'
			? navigation.state !== 'idle'
			: navigation.state === state;
	return (
		isPendingState &&
		navigation.formAction === (formAction ?? contextualFormAction) &&
		navigation.formMethod === formMethod
	);
}

export const crypto = {
	random: (bytes = 32) => {
		return cryptoJS.lib.WordArray.random(bytes).toString(
			cryptoJS.enc.Base64url,
		);
	},
};

const DEFAULT_REDIRECT = '/';

/**
 * The `safeRedirect` function checks if a given URL is valid and matches any domain-wide or specific
 * redirects, returning the appropriate redirect URL or a default redirect if none are found.
 * @param {FormDataEntryValue | string | null | undefined} to - The `to` parameter can be a
 * `FormDataEntryValue`, a string, or `null`/`undefined`. It represents the destination URL or path for
 * the redirect.
 * @param {string} defaultRedirect - The defaultRedirect parameter is a string that represents the
 * default URL to redirect to if the provided "to" parameter is not valid or does not match any of the
 * specified conditions.
 * @returns a promise that resolves to a string.
 */
export async function safeRedirect(
	to: FormDataEntryValue | string | null | undefined,
	defaultRedirect: string = DEFAULT_REDIRECT,
) {
	if (!to || typeof to !== 'string') {
		return defaultRedirect;
	}

	if (!to.startsWith('/') || to.startsWith('//')) {
		try {
			const toUrl = new URL(to);
			const redirects = await prisma.redirect.findMany({
				select: { hostname: true, href: true, isHostnameWide: true },
			});

			// Check if there is a domain wide redirect and return if true.
			const hostnameWideRedirects = redirects
				.map((item: any) => {
					if (item.isHostnameWide === true) return item.hostname;
				})
				.filter((item: any) => item);

			if (hostnameWideRedirects.includes(toUrl.hostname)) {
				return to;
			}

			// Check if there is a specific href for the given redirect.
			const hrefRedirects = redirects
				.map((item: any) => {
					if (item.isHostnameWide === false) return item.href;
				})
				.filter((item: any) => item);

			if (hrefRedirects.includes(toUrl.href)) {
				return to;
			}

			return defaultRedirect;
		} catch (error) {
			console.log(error);
			return defaultRedirect;
		}
	}

	return to;
}
